import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicResponseFormComponent } from './pages/public-response-form/public-response-form.component';
import { PublicResponseFormResultComponent } from '@modules/public-response-form/pages/public-response-form-result/public-response-form-result.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: PublicResponseFormComponent,
      },
      {
        path: 'result',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PublicResponseFormResultComponent,
          },
          {
            path: ':resultType',
            component: PublicResponseFormResultComponent,
          },
        ],
      },
      {
        path: ':clientId/:communicationId',
        component: PublicResponseFormComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicResponseFormRouterModule {}
