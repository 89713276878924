import { Injectable } from '@angular/core';
import { TwoFactorAuthService, UserModel } from '@capturum/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthConfig, AuthService } from '@node_modules/@capturum/auth';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorService extends TwoFactorAuthService {
  constructor(
    config: AuthConfig,
    http: HttpClient,
    sanitizer: DomSanitizer,
    authService: AuthService,
    private httpClient: HttpClient,
  ) {
    super(config, http, sanitizer, authService);
  }

  public login(postData: {
    user_id: string;
    method: string;
    key: string;
  }): Observable<{ status: string; token: string; user: UserModel }> {
    return this.httpClient.post<{ token: string; user: any }>(`${this.endpoint}/login2fa`, postData).pipe(
      map((result: any) => {
        if (result.status === 'ok') {
          if (result.user) {
            if (result.user.roles !== undefined && result.user.roles.data !== undefined) {
              result.user.roles = result.user.roles.data;
            }

            const user = result.user;

            localStorage.setItem('user', JSON.stringify(user));
          }

          localStorage.setItem('token', result.token);

          return result;
        }
      }),
    );
  }
}
