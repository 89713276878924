import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LastAction, LastActionsService } from '@core/services/last-actions.service';
import { AllRoutes } from '@core/enums/routes';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { CapturumButtonModule } from '@capturum/ui/button';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CapturumButtonModule, DatePipe, TranslateModule],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input()
  public sidebarVisible = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onHide: EventEmitter<void> = new EventEmitter<void>();

  public actions: LastAction[] = [];
  public routes = AllRoutes;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private clientStorageService: LastActionsService,
    private router: Router,
  ) {
    clientStorageService.loadActions$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        return this.refreshActionsList();
      });
  }

  public ngOnInit(): void {
    this.loadLastActions();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadLastActions(): void {
    this.clientStorageService.sliceActions(10);
    this.actions = this.clientStorageService.getActions();
  }

  public refreshActionsList(): void {
    this.actions = this.clientStorageService.getActions();
  }

  public cleanHistory(): void {
    this.clientStorageService.sliceActions(0);
    this.actions = this.clientStorageService.getActions();
  }

  public closeSidebar(): void {
    this.sidebarVisible = false;
    this.onHide.emit();
  }

  public navigateToPage(link: string): void {
    this.router.navigate([link, 'review']).then(() => {
      this.closeSidebar();
    });
  }
}
