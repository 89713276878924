import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllRoutes } from '@core/enums/routes';
import { ClientInformation } from '@core/enums/client-contact-information.enum';
import { ResultMessage } from '@core/models/result-message.model';

@Component({
  selector: 'app-public-response-form-result',
  templateUrl: './public-response-form-result.component.html',
  styleUrls: [
    './public-response-form-result.component.scss',
    '../../../../shared/layouts/basic-layout/components/header/header.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [],
})
export class PublicResponseFormResultComponent {
  public resultType: string;
  public routes: typeof AllRoutes = AllRoutes;
  public clientInformation = ClientInformation;

  constructor(private route: ActivatedRoute) {
    route.params.subscribe(({ resultType }) => {
      return (this.resultType = resultType);
    });
  }

  public resultMessage(): ResultMessage {
    switch (this.resultType) {
      case 'success':
        return {
          description: 'Uw aanvraag is verzonden. Bedankt voor uw interesse. De documentatie wordt u toegezonden.',
          isError: false,
        };
      case 'error':
        return {
          description: 'Er is iets misgegaan. Uw aanvraag is niet verzonden.',
          isError: true,
        };
      default:
        return {
          description: 'Er is iets misgegaan. Uw aanvraag is niet verzonden.',
          isError: true,
        };
    }
  }
}
