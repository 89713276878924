import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, ContentChildren, NgModule } from '@angular/core';
import { Subject, merge, fromEvent, Observable } from 'rxjs';
import { takeUntil, filter, map } from 'rxjs/operators';
import { CapturumTemplateDirective } from '@capturum/ui/api';
import { Element } from '@angular/compiler';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/router';
import { NavigationEnd, RouterModule } from '@angular/router';
import * as i3 from '@capturum/ui/notification-badge';
import { CapturumNotificationBadgeModule } from '@capturum/ui/notification-badge';
import { CapturumSidebarMenuModule } from '@capturum/ui/sidebar-menu';
const _c0 = () => [];
const _c1 = a0 => [a0];
const _c2 = a0 => ({
  exact: a0
});
const _c3 = a0 => ({
  $implicit: a0
});
function NavItemComponent_div_6_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 9)(1, "span", 10);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "i", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r1 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(3, _c1, child_r1.routerUrl))("routerLinkActiveOptions", i0.ɵɵpureFunction1(5, _c2, child_r1.exact !== undefined ? child_r1.exact : false));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(child_r1.title);
  }
}
function NavItemComponent_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtemplate(1, NavItemComponent_div_6_a_1_Template, 4, 7, "a", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.item.children);
  }
}
function NavItemComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", item_r3.title, " ");
  }
}
function NavItemComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "cap-notification-badge", 12);
    i0.ɵɵelement(1, "i");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    i0.ɵɵproperty("showBadge", !!item_r4.notificationBadgeOptions)("options", item_r4.notificationBadgeOptions);
    i0.ɵɵadvance();
    i0.ɵɵclassMapInterpolate1("icon-menu fa-fw ", item_r4.icon, "");
  }
}
function HorizontalNavbarComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 5);
  }
}
function HorizontalNavbarComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 6);
  }
}
function HorizontalNavbarComponent_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "cap-nav-item", 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("item", item_r1)("contentTemplate", ctx_r1.navItemTemplate)("iconTemplate", ctx_r1.navIconTemplate);
  }
}
class NavItemComponent {
  constructor(router) {
    this.router = router;
    this.destroy$ = new Subject();
    this.isActiveItem$ = this.router.events.pipe(takeUntil(this.destroy$), filter(routerEvent => routerEvent instanceof NavigationEnd), map(event => {
      if (this.item.activeSubRoute) {
        return event.url.includes(this.item.activeSubRoute);
      }
      return false;
    }));
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  static {
    this.ɵfac = function NavItemComponent_Factory(t) {
      return new (t || NavItemComponent)(i0.ɵɵdirectiveInject(i1.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NavItemComponent,
      selectors: [["cap-nav-item"]],
      inputs: {
        item: "item",
        contentTemplate: "contentTemplate",
        iconTemplate: "iconTemplate"
      },
      decls: 11,
      vars: 24,
      consts: [["default", ""], ["defaultIcon", ""], [1, "nav-link", "nav-item", "px-0", 3, "routerLinkActive", "ngClass", "routerLink", "routerLinkActiveOptions"], [1, "nav-item-overlay"], [1, "nav-item-div"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "dropdown-menu", 4, "ngIf"], [1, "dropdown-menu"], ["class", "dropdown-item", "routerLinkActive", "active", 3, "routerLink", "routerLinkActiveOptions", 4, "ngFor", "ngForOf"], ["routerLinkActive", "active", 1, "dropdown-item", 3, "routerLink", "routerLinkActiveOptions"], [1, "nav-item-title"], [1, "fa", "fa-chevron-right"], [1, "d-block", 3, "showBadge", "options"]],
      template: function NavItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "a", 2);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵelement(2, "div", 3);
          i0.ɵɵelementStart(3, "div", 4);
          i0.ɵɵelementContainer(4, 5)(5, 5);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(6, NavItemComponent_div_6_Template, 2, 1, "div", 6);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(7, NavItemComponent_ng_template_7_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(9, NavItemComponent_ng_template_9_Template, 2, 5, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const default_r5 = i0.ɵɵreference(8);
          const defaultIcon_r6 = i0.ɵɵreference(10);
          i0.ɵɵclassProp("dropdown", ctx.item == null ? null : ctx.item.children)("active-item", i0.ɵɵpipeBind1(1, 13, ctx.isActiveItem$));
          i0.ɵɵproperty("routerLinkActive", (ctx.item == null ? null : ctx.item.children) ? "" : "active")("ngClass", ctx.item.styleClass)("routerLink", (ctx.item == null ? null : ctx.item.children) ? i0.ɵɵpureFunction0(15, _c0) : i0.ɵɵpureFunction1(16, _c1, ctx.item.routerUrl))("routerLinkActiveOptions", i0.ɵɵpureFunction1(18, _c2, ctx.item.exact !== undefined ? ctx.item.exact : false));
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngTemplateOutlet", ctx.iconTemplate || defaultIcon_r6)("ngTemplateOutletContext", i0.ɵɵpureFunction1(20, _c3, ctx.item));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.contentTemplate || default_r5)("ngTemplateOutletContext", i0.ɵɵpureFunction1(22, _c3, ctx.item));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.item == null ? null : ctx.item.children);
        }
      },
      dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, i1.RouterLink, i1.RouterLinkActive, i3.CapturumNotificationBadgeComponent, i2.AsyncPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavItemComponent, [{
    type: Component,
    args: [{
      selector: 'cap-nav-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<a class=\"nav-link nav-item px-0\"\n   [routerLinkActive]=\"item?.children ? '' : 'active'\"\n   [class.dropdown]=\"item?.children\"\n   [ngClass]=\"item.styleClass\"\n   [routerLink]=\"item?.children ? [] : [item.routerUrl]\"\n   [class.active-item]=\"isActiveItem$ | async\"\n   [routerLinkActiveOptions]=\"{exact: item.exact !== undefined ? item.exact : false}\">\n    <div class=\"nav-item-overlay\">\n    </div>\n\n    <div class=\"nav-item-div\">\n        <ng-container [ngTemplateOutlet]=\"iconTemplate || defaultIcon\" [ngTemplateOutletContext]=\"{$implicit: item}\">\n        </ng-container>\n\n        <ng-container [ngTemplateOutlet]=\"contentTemplate || default\" [ngTemplateOutletContext]=\"{$implicit: item}\">\n        </ng-container>\n    </div>\n\n    <div class=\"dropdown-menu\" *ngIf=\"item?.children\">\n        <a class=\"dropdown-item\"\n           *ngFor=\"let child of item.children\"\n           routerLinkActive=\"active\"\n           [routerLink]=\"[child.routerUrl]\"\n           [routerLinkActiveOptions]=\"{exact: child.exact !== undefined ? child.exact : false}\">\n            <span class=\"nav-item-title\">{{ child.title }}</span>\n\n            <i class=\"fa fa-chevron-right\"></i>\n        </a>\n    </div>\n</a>\n\n<ng-template #default let-item>\n    <span class=\"nav-item-title\">\n        {{ item.title }}\n    </span>\n</ng-template>\n\n<ng-template #defaultIcon let-item>\n    <cap-notification-badge class=\"d-block\" [showBadge]=\"!!item.notificationBadgeOptions\"\n                            [options]=\"item.notificationBadgeOptions\">\n        <i class=\"icon-menu fa-fw {{ item.icon }}\"></i>\n    </cap-notification-badge>\n</ng-template>\n"
    }]
  }], () => [{
    type: i1.Router
  }], {
    item: [{
      type: Input
    }],
    contentTemplate: [{
      type: Input
    }],
    iconTemplate: [{
      type: Input
    }]
  });
})();
class HorizontalNavbarComponent {
  constructor(cdr) {
    this.cdr = cdr;
    /**
     * The items to be displayed in the navbar
     */
    this.items = [];
    /**
     * the style class to be applied on the navbar
     */
    this.styleClass = '';
    this.destroy$ = new Subject();
  }
  ngOnInit() {
    this.isOnline().pipe(takeUntil(this.destroy$)).subscribe(isOnline => {
      if (this.items) {
        if (!isOnline) {
          this.filteredItems = [...this.items].filter(item => item.offlineAvailable === true);
        } else {
          this.filteredItems = [...this.items];
        }
      }
      this.cdr.detectChanges();
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
  }
  ngAfterViewInit() {
    this.templates.forEach(template => {
      switch (template.getType()) {
        case 'icon':
          this.navIconTemplate = template.template;
          break;
        case 'nav-item':
          this.navItemTemplate = template.template;
          break;
      }
    });
    this.cdr.detectChanges();
  }
  ngOnChanges() {
    // Check whether this.items is iterable
    if (this.items && typeof this.items[Symbol.iterator] === 'function') {
      this.filteredItems = [...this.items];
      if (!navigator.onLine) {
        this.filteredItems = [...this.items].filter(item => item.offlineAvailable === true);
      }
      this.observe();
      this.cdr.detectChanges();
    }
  }
  isOnline() {
    return merge(fromEvent(window, 'offline').pipe(map(() => false)), fromEvent(window, 'online').pipe(map(() => true)), new Observable(sub => {
      sub.next(navigator.onLine);
    }));
  }
  observe() {
    const navItemElements = document.querySelectorAll('.nav-item');
    const lastItemObserver = new IntersectionObserver(event => {
      this.lastItemVisible = event[0].isIntersecting;
    });
    const firstItemIntersection = new IntersectionObserver(event => {
      this.firstItemVisible = event[0].isIntersecting;
    });
    if (navItemElements[navItemElements.length - 1] && navItemElements[navItemElements.length - 1] instanceof Element) {
      lastItemObserver.observe(navItemElements[navItemElements.length - 1]);
      firstItemIntersection.observe(navItemElements[0]);
      this.cdr.detectChanges();
    }
  }
  static {
    this.ɵfac = function HorizontalNavbarComponent_Factory(t) {
      return new (t || HorizontalNavbarComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HorizontalNavbarComponent,
      selectors: [["cap-horizontal-navbar"]],
      contentQueries: function HorizontalNavbarComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, CapturumTemplateDirective, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
        }
      },
      inputs: {
        items: "items",
        styleClass: "styleClass"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 6,
      vars: 5,
      consts: [["id", "navbarNavAltMarkup", 1, "collapse", "navbar-collapse"], [1, "navbar-nav", "justify-content-center"], ["class", "nav-bar-gradient", 4, "ngIf"], ["class", "nav-bar-gradient-left", 4, "ngIf"], [4, "ngFor", "ngForOf"], [1, "nav-bar-gradient"], [1, "nav-bar-gradient-left"], [3, "item", "contentTemplate", "iconTemplate"]],
      template: function HorizontalNavbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "nav")(1, "div", 0)(2, "div", 1);
          i0.ɵɵtemplate(3, HorizontalNavbarComponent_div_3_Template, 1, 0, "div", 2)(4, HorizontalNavbarComponent_div_4_Template, 1, 0, "div", 3)(5, HorizontalNavbarComponent_ng_container_5_Template, 2, 3, "ng-container", 4);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵclassMap("cap-horizontal-navbar navbar navbar-expand " + ctx.styleClass);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", !ctx.lastItemVisible);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.firstItemVisible);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.filteredItems);
        }
      },
      dependencies: [i2.NgForOf, i2.NgIf, NavItemComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HorizontalNavbarComponent, [{
    type: Component,
    args: [{
      selector: 'cap-horizontal-navbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<nav [class]=\"'cap-horizontal-navbar navbar navbar-expand ' + styleClass\">\n    <div class=\"collapse navbar-collapse\" id=\"navbarNavAltMarkup\">\n        <div class=\"navbar-nav justify-content-center\">\n            <div *ngIf=\"!lastItemVisible\" class=\"nav-bar-gradient\">\n            </div>\n\n            <div *ngIf=\"!firstItemVisible\" class=\"nav-bar-gradient-left\"></div>\n            <ng-container *ngFor=\"let item of filteredItems\">\n                <cap-nav-item [item]=\"item\" [contentTemplate]=\"navItemTemplate\" [iconTemplate]=\"navIconTemplate\">\n                </cap-nav-item>\n            </ng-container>\n        </div>\n    </div>\n</nav>\n"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    items: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [CapturumTemplateDirective]
    }]
  });
})();
class HorizontalNavbarModule {
  static {
    this.ɵfac = function HorizontalNavbarModule_Factory(t) {
      return new (t || HorizontalNavbarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: HorizontalNavbarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule, CapturumSidebarMenuModule, CapturumNotificationBadgeModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HorizontalNavbarModule, [{
    type: NgModule,
    args: [{
      declarations: [HorizontalNavbarComponent, NavItemComponent],
      imports: [CommonModule, RouterModule, CapturumSidebarMenuModule, CapturumNotificationBadgeModule],
      exports: [HorizontalNavbarComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { HorizontalNavbarComponent, HorizontalNavbarModule, NavItemComponent };
