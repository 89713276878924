import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavBarItem } from '@node_modules/@capturum/ui/api';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { first, Subject, takeUntil, timer } from '@node_modules/rxjs';
import { MessagesCounterApiService } from '@shared/services/messages-counter-api.service';
import { FormsCounterApiService } from '@shared/services/forms-counter-api.service';
import { CampaignsCounterApiService } from '@shared/services/campaigns-counter-api.service';
import { NotificationBadgeOptions } from '@node_modules/@capturum/ui/api/models/notification-badge-options.model';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HorizontalNavbarModule } from '@capturum/ui/horizontal-navbar';

import { HeaderComponent } from './components/header/header.component';

@Component({
  selector: 'app-layout-basic',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [HeaderComponent, HorizontalNavbarModule, SidebarComponent, RouterOutlet],
})
export class BasicLayoutComponent implements OnInit, OnDestroy {
  public navbarVisible = true;
  public sidebarVisible = false;
  public navBarMenuItems: NavBarItem[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private messagesCounterApiService: MessagesCounterApiService,
    private formsCounterApiService: FormsCounterApiService,
    private campaignsCounterApiService: CampaignsCounterApiService,
  ) {}

  public ngOnInit(): void {
    this.buildMenu();
    this.setCounters();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public buildMenu(): void {
    const notificationBadgeOptions: NotificationBadgeOptions = {
      badgeSize: '1.2rem',
      backgroundColor: '#fa4331',
      fontColor: '#fff',
      fontSize: '0.7rem',
      showBorder: false,
      showBadge: false,
    };

    this.navBarMenuItems = [
      {
        title: this.translateService.instant('menu.clients.label'),
        icon: 'fas fa-user',
        routerUrl: '/admin/clients',
        exact: true,
      },
      {
        title: this.translateService.instant('menu.funds.label'),
        icon: 'fas fa-sack-dollar',
        routerUrl: '/admin/projects',
        exact: true,
      },
      {
        title: this.translateService.instant('menu.campaigns.label'),
        icon: 'fas fa-paper-plane',
        routerUrl: '/admin/campaigns',
        key: 'campaigns',
        exact: true,
        notificationBadgeOptions: { ...notificationBadgeOptions },
      },
      {
        title: this.translateService.instant('menu.forms.label'),
        icon: 'fas fa-file',
        routerUrl: '/admin/forms',
        key: 'forms',
        exact: true,
        notificationBadgeOptions: { ...notificationBadgeOptions },
      },
      {
        title: this.translateService.instant('menu.suppliers.label'),
        icon: 'far fa-building',
        routerUrl: '/admin/suppliers',
        exact: true,
      },
      {
        title: this.translateService.instant('menu.inbox.label'),
        icon: 'fas fa-envelope',
        routerUrl: '/admin/inbox',
        key: 'inbox',
        exact: true,
        notificationBadgeOptions: { ...notificationBadgeOptions },
      },
    ];
  }

  public toggleSidebar(sidebarVisible?: boolean): void {
    if (sidebarVisible) {
      this.sidebarVisible = sidebarVisible;
    } else {
      this.sidebarVisible = !this.sidebarVisible;
    }
  }

  private setCounters(): void {
    timer(0, 30000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.messagesCounterApiService.getMessagesCount().pipe(first()).subscribe();
        this.campaignsCounterApiService.getCampaignCount().pipe(first()).subscribe();
        this.formsCounterApiService.getFormsCount().pipe(first()).subscribe();
      });

    this.messagesCounterApiService.messagesCount$.pipe(takeUntil(this.destroy$)).subscribe((count) => {
      this.updateMenuCount('inbox', count);
    });

    this.campaignsCounterApiService.campaignsCount$.pipe(takeUntil(this.destroy$)).subscribe((count) => {
      this.updateMenuCount('campaigns', count);
    });

    this.formsCounterApiService.formsCount$.pipe(takeUntil(this.destroy$)).subscribe((count) => {
      this.updateMenuCount('forms', count);
    });
  }

  private updateMenuCount(menuItemKey: string, count: number): void {
    const menuItemIndex = this.navBarMenuItems.findIndex((item) => {
      return item.key === menuItemKey;
    });
    const newLabel = count > 99 ? '99+' : count.toString();

    if (this.navBarMenuItems[menuItemIndex].notificationBadgeOptions.label === newLabel) {
      return;
    }

    this.navBarMenuItems[menuItemIndex].notificationBadgeOptions.label = newLabel;
    this.navBarMenuItems[menuItemIndex].notificationBadgeOptions.showBadge = count > 0;

    this.navbarVisible = false;

    setTimeout(() => {
      this.navbarVisible = true;
    }, 0);
  }
}
