import { Component } from '@angular/core';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { StorageService } from '@shared/services/storage.service';
import { filter } from 'rxjs/operators';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, ConfirmDialogModule, ToastModule, TranslateModule],
})
export class AppComponent {
  constructor(
    private themeService: ThemeService,
    private translateService: TranslateService,
    private router: Router,
    private storageService: StorageService,
  ) {
    this.themeService.initializeTheme();

    this.themeService.setProps({
      [CSSVarNames.Font]: 'Open Sans',
      [CSSVarNames.Primary]: '#0160b8',
      ['--primary-color']: '#0160b8',
      [CSSVarNames.PrimaryShadow]: '#01539f',
      [CSSVarNames.Success]: '#4cd964',
      [CSSVarNames.Error]: '#e3342f',
    });

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
      )
      .subscribe((event: NavigationEnd) => {
        const splitUrl = event.url.split('/');
        const page = splitUrl[2];

        this.translateService.setDefaultLang('nl');

        if (
          splitUrl.length > 3 &&
          new RegExp(/^.*[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/).test(event.url) &&
          !event.url.includes('edit')
        ) {
          this.storageService.set(`${page}-page`, event.url);
        } else {
          const pageStorageValue = this.storageService.get(`${page}-page`);

          if (pageStorageValue && splitUrl.length === 3) {
            this.storageService.remove(`${page}-page`);
          }
        }
      });
  }
}
