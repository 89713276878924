<div class="public-response-form">
  <header class="header-picture"></header>

  <header class="row m-0 header-styling">
    <div class="header-container col-lg-6 px-0 py-3">
      <div class="container align-items-center"></div>

      <div class="header-info">
        <div class="gray-text">Dank voor uw interesse in</div>
        <div class="gray-text">{{ client?.project_name }}</div>
        <div class="black-text">{{ client?.concat_name }}</div>
      </div>
    </div>
  </header>

  <div class="mx-2">
    <div class="col-lg-12 form-display">
      <div class="col-lg-3 form-style">
        <div class="content container mt-4">
          @if (isPageLoaded) {
            <div class="content-header">
              <h1>voorkeuren</h1>
            </div>
          }

          <div class="content-body">
            @if (isPageLoaded) {
              <form class="ng-submitted" [formGroup]="form">
                <div class="form-group">
                  <label
                    >Informatie ontvangen per
                    @if (validateUnitsInterested) {
                      <span>*</span>
                    }
                  </label>
                  <cap-dropdown
                    class="w-100"
                    formControlName="sending_method"
                    [options]="sendingTypes"
                    (change)="updateCommunicationType($event)"
                    appendTo="body"
                    [filter]="true"
                    filterBy="sendingTypes"
                    panelStyleClass="table-filter-panel">
                  </cap-dropdown>
                </div>
                <div class="form-group">
                  <label class="col-lg-8">
                    {{
                      'In hoeveel participaties à €' +
                        (client?.participation_size | number: '' : 'nl-NL') +
                        ' heeft u mogelijk interesse?'
                    }}
                  </label>
                  <div class="btn-group col-lg-4">
                    <cap-input filter="num" formControlName="units_interest"></cap-input>
                  </div>
                </div>
                <div>
                  <label>Opmerking</label>
                  <div class="input-container">
                    <cap-textarea class="textarea large" formControlName="remarks"></cap-textarea>
                  </div>
                </div>
                <div class="buttons">
                  <cap-button
                    styleClass="primary"
                    [label]="'Versturen'"
                    (click)="submitResponseForm()"
                    icon="fas fa-angle-right"
                    iconPosition="right">
                  </cap-button>
                </div>
              </form>
            } @else {
              <div class="content mt-4 container p-3">
                <div class="content-body p-5 bg-white border-3">
                  <div class="row">
                    <div class="col text-center d-flex align-middle justify-content-center flex-column">
                      <h2>Bezig met laden...</h2>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div class="col-lg-3 form-style">
        <div class="content container mt-4">
          @if (isPageLoaded) {
            <div class="content-header">
              <h1>contactgegevens</h1>
            </div>
          }

          <div class="content-body">
            @if (isPageLoaded) {
              <form class="ng-submitted" [formGroup]="form">
                @if (showPostField) {
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6">
                        <label>Straat *</label>
                        <cap-input class="input-design" formControlName="street"></cap-input>
                      </div>
                      <div class="col-md-3">
                        <label>Huis nr. *</label>
                        <cap-input class="input-design" formControlName="housenumber"></cap-input>
                      </div>
                      <div class="col-md-3">
                        <label>Toevoeging</label>
                        <cap-input class="input-design" formControlName="addition"></cap-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Postcode *</label>
                        <cap-input class="input-design" formControlName="zipcode"></cap-input>
                      </div>
                      <div class="col-md-8">
                        <label>Woonplaats *</label>
                        <cap-input class="input-design" formControlName="city"></cap-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label>Land *</label>
                        <cap-input class="input-design" formControlName="country"></cap-input>
                      </div>
                      <div class="col-md-6">
                        <label>Bedrijfsnaam</label>
                        <cap-input class="input-design" formControlName="company_name"></cap-input>
                      </div>
                    </div>
                  </div>
                }
                @if (showEmailField) {
                  <div class="form-group">
                    <label>E-mailadres *</label>
                    <div class="w-100 btn-group">
                      <cap-input class="input-design col-md-12" formControlName="email"></cap-input>
                    </div>
                  </div>
                }
                @if (showButton) {
                  <cap-button
                    class="button-design"
                    type="enable"
                    [label]="'Contactgevens aanpassen'"
                    (onClick)="enable()">
                  </cap-button>
                }
              </form>
            } @else {
              <div class="content mt-4 container p-3">
                <div class="content-body p-5 bg-white border-3">
                  <div class="row">
                    <div class="col text-center d-flex align-middle justify-content-center flex-column">
                      <h2>Bezig met laden...</h2>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="footer-design">
    Handelsweg 59C | 1181 ZA Amstelveen | Tel: +31 (0)20 643 83 81 | info&#64;reyersen.nl | www.reyersen.nl
  </footer>
</div>
