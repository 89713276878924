import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { Positions } from '@capturum/ui/api';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
const _c1 = a0 => [a0, "position-relative"];
function CapturumNotificationBadgeComponent_div_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function CapturumNotificationBadgeComponent_div_1_i_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r0.icon);
  }
}
function CapturumNotificationBadgeComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtemplate(1, CapturumNotificationBadgeComponent_div_1_span_1_Template, 2, 1, "span", 4)(2, CapturumNotificationBadgeComponent_div_1_i_2_Template, 1, 1, "i", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", ctx_r0.getBadgeStyles());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.icon && ctx_r0.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.icon);
  }
}
function CapturumNotificationBadgeComponent_ng_content_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 0, ["*ngIf", "hasPosition()"]);
  }
}
class CapturumNotificationBadgeComponent {
  constructor() {
    this.badgeSize = '1.5rem';
    this.borderRadius = '50%';
    this.position = Positions.TOP_RIGHT;
    this.showBorder = true;
    this.showBadge = true;
    this.borderType = 'solid';
    this.borderSize = '1px';
    this.borderColor = '';
  }
  ngOnInit() {
    if (this.fontSize === undefined) {
      this.fontSize = this.getCSSCenter(this.badgeSize);
    }
  }
  ngOnChanges({
    options
  }) {
    if (options && options.currentValue !== undefined) {
      for (const name of Object.keys(options.currentValue)) {
        this[name] = options.currentValue[name];
      }
    }
  }
  getBadgeStyles() {
    const width = this.width || this.badgeSize;
    const height = this.height || this.badgeSize;
    const positioning = {};
    if (this.hasPosition()) {
      positioning[this.position.split('-')[0]] = this.getCSSCenter(`-${height}`);
      positioning[this.position.split('-')[1]] = this.getCSSCenter(`-${width}`);
    }
    return {
      ...positioning,
      width,
      height,
      backgroundColor: this.backgroundColor,
      color: this.fontColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      borderRadius: this.borderRadius,
      border: this.showBorder ? `${this.borderType} ${this.borderSize} ${this.borderColor}` : 'none'
    };
  }
  hasPosition() {
    return this.position !== 'none';
  }
  getCSSCenter(CSSUnit) {
    return `calc(${CSSUnit} / 2)`;
  }
  static {
    this.ɵfac = function CapturumNotificationBadgeComponent_Factory(t) {
      return new (t || CapturumNotificationBadgeComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumNotificationBadgeComponent,
      selectors: [["cap-notification-badge"]],
      inputs: {
        badgeSize: "badgeSize",
        width: "width",
        height: "height",
        borderRadius: "borderRadius",
        position: "position",
        label: "label",
        icon: "icon",
        fontColor: "fontColor",
        fontSize: "fontSize",
        fontWeight: "fontWeight",
        backgroundColor: "backgroundColor",
        showBorder: "showBorder",
        showBadge: "showBadge",
        borderType: "borderType",
        borderSize: "borderSize",
        borderColor: "borderColor",
        options: "options"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 5,
      consts: [[3, "ngClass"], ["class", "cap-notification-badge d-flex align-items-center justify-content-center position-absolute", 3, "ngStyle", 4, "ngIf"], [4, "ngIf"], [1, "cap-notification-badge", "d-flex", "align-items-center", "justify-content-center", "position-absolute", 3, "ngStyle"], ["class", "overflow-hidden", 4, "ngIf"], [3, "ngClass", 4, "ngIf"], [1, "overflow-hidden"]],
      template: function CapturumNotificationBadgeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "span", 0);
          i0.ɵɵtemplate(1, CapturumNotificationBadgeComponent_div_1_Template, 3, 3, "div", 1)(2, CapturumNotificationBadgeComponent_ng_content_2_Template, 1, 0, "ng-content", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx.hasPosition() ? "d-inline-block" : ""));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.showBadge);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.hasPosition());
        }
      },
      dependencies: [i1.NgClass, i1.NgIf, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumNotificationBadgeComponent, [{
    type: Component,
    args: [{
      selector: 'cap-notification-badge',
      template: "<span [ngClass]=\"[hasPosition() ? 'd-inline-block' : '', 'position-relative']\">\n    <div class=\"cap-notification-badge d-flex align-items-center justify-content-center position-absolute\"\n         *ngIf=\"showBadge\"\n         [ngStyle]=\"getBadgeStyles()\">\n        <span *ngIf=\"!icon && label\" class=\"overflow-hidden\">{{ label }}</span>\n\n        <i *ngIf=\"icon\" [ngClass]=\"icon\"></i>\n\n    </div>\n\n    <ng-content *ngIf=\"hasPosition()\"></ng-content>\n\n</span>\n"
    }]
  }], null, {
    badgeSize: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    borderRadius: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    fontColor: [{
      type: Input
    }],
    fontSize: [{
      type: Input
    }],
    fontWeight: [{
      type: Input
    }],
    backgroundColor: [{
      type: Input
    }],
    showBorder: [{
      type: Input
    }],
    showBadge: [{
      type: Input
    }],
    borderType: [{
      type: Input
    }],
    borderSize: [{
      type: Input
    }],
    borderColor: [{
      type: Input
    }],
    options: [{
      type: Input
    }]
  });
})();
class CapturumNotificationBadgeModule {
  static {
    this.ɵfac = function CapturumNotificationBadgeModule_Factory(t) {
      return new (t || CapturumNotificationBadgeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumNotificationBadgeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumNotificationBadgeModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumNotificationBadgeComponent],
      exports: [CapturumNotificationBadgeComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumNotificationBadgeComponent, CapturumNotificationBadgeModule };
