<section class="sidebar" [class.d-block]="sidebarVisible" [class.d-none]="!sidebarVisible">
  <div class="heading">
    <span class="pl-4">{{ 'sidebar.last-visited-clients.label' | translate }}</span>

    <cap-button (click)="closeSidebar()" styleClass="secondary" icon="fas fa-times"> </cap-button>
  </div>

  <div class="list">
    <hr />

    @for (action of actions; track action) {
      <a (click)="navigateToPage(action.link)">
        <div class="list-item">
          <p class="font-weight-bolder">{{ action.name }}</p>
          <p>
            <span class="text-muted">{{ action.visited_at | date: 'dd - MM - yyyy @ HH:mm' }}</span>
          </p>
        </div>
      </a>
    }
  </div>

  <div class="footer">
    <cap-button (click)="cleanHistory()" styleClass="secondary" class="ml-auto" icon="fas fa-trash"> </cap-button>
  </div>
</section>
