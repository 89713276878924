import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import * as i1 from '@capturum/ui/api';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i3 from '@angular/forms';
import { NgControl, NgModel, FormsModule } from '@angular/forms';
import * as i4 from '@capturum/ui/input';
import { CapturumInputModule } from '@capturum/ui/input';

/**
 * A password component
 *
 * Example:
 * ```html
 *     <cap-password placeholder='Placeholder' label='Label' [toggleMask]='true'></cap-password>
 * ```
 */
function CapturumPasswordComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵlistener("click", function CapturumPasswordComponent_div_1_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.displayToggle());
    });
    i0.ɵɵelement(1, "i");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.isPasswordTypeText ? ctx_r1.hideIcon || "fal fa-eye-slash" : ctx_r1.showIcon || "fal fa-eye");
  }
}
function CapturumPasswordComponent_cap_validator_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cap-validator", 4);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("control", ctx_r1.control)("customName", ctx_r1.label);
  }
}
class CapturumPasswordComponent extends ValueAccessorBase {
  constructor(validatorService, injector) {
    super();
    this.validatorService = validatorService;
    this.injector = injector;
    /**
     * The placeholder for the input to display when there is no input filled in
     */
    this.placeholder = '';
    /**
     * The styleClass of the Input component
     */
    this.styleClass = '';
    /**
     * The variable to be set when you want to enable autocomplete
     */
    this.autocomplete = 'off';
    /**
     * The action you want to emit when there is a change in the input
     */
    this.onInput = new EventEmitter();
    /**
     * The action you want to emit when the input loses focus
     */
    this.blur = new EventEmitter();
    /**
     * The action you want to emit when input is in focus
     */
    this.focus = new EventEmitter();
    /**
     * The action you want to emit when input a keyboard key up event happens
     */
    this.onKeyUp = new EventEmitter();
    this.isPasswordTypeText = false;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
  /**
   * Password field type text or password
   */
  displayToggle() {
    this.isPasswordTypeText = !this.isPasswordTypeText;
    if (this.autoCloseTimeout) {
      setTimeout(() => {
        this.isPasswordTypeText = false;
      }, this.autoCloseTimeout);
    }
  }
  onInputEvent($event) {
    this.touch();
    this.onInput.emit($event);
  }
  onBlur($event) {
    this.touch();
    this.blur.emit();
  }
  onFocus($event) {
    this.focus.emit();
  }
  static {
    this.ɵfac = function CapturumPasswordComponent_Factory(t) {
      return new (t || CapturumPasswordComponent)(i0.ɵɵdirectiveInject(i1.ValidatorService), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumPasswordComponent,
      selectors: [["cap-password"]],
      viewQuery: function CapturumPasswordComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NgModel, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.model = _t.first);
        }
      },
      inputs: {
        placeholder: "placeholder",
        label: "label",
        disabled: "disabled",
        readonly: "readonly",
        styleClass: "styleClass",
        toggleMask: "toggleMask",
        hideIcon: "hideIcon",
        showIcon: "showIcon",
        autoCloseTimeout: "autoCloseTimeout",
        autocomplete: "autocomplete"
      },
      outputs: {
        onInput: "onInput",
        blur: "blur",
        focus: "focus",
        onKeyUp: "onKeyUp"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumPasswordComponent)]), i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 14,
      consts: [["class", "display-password-toggle", 3, "click", 4, "ngIf"], [3, "ngModelChange", "keyup", "input", "blur", "focus", "autocomplete", "type", "placeholder", "readonly", "disabled", "styleClass", "label", "ngModel"], [3, "control", "customName", 4, "ngIf"], [1, "display-password-toggle", 3, "click"], [3, "control", "customName"]],
      template: function CapturumPasswordComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵtemplate(1, CapturumPasswordComponent_div_1_Template, 2, 2, "div", 0);
          i0.ɵɵelementStart(2, "cap-input", 1);
          i0.ɵɵtwoWayListener("ngModelChange", function CapturumPasswordComponent_Template_cap_input_ngModelChange_2_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵlistener("keyup", function CapturumPasswordComponent_Template_cap_input_keyup_2_listener($event) {
            return ctx.onKeyUp.emit($event);
          })("input", function CapturumPasswordComponent_Template_cap_input_input_2_listener($event) {
            return ctx.onInputEvent($event);
          })("blur", function CapturumPasswordComponent_Template_cap_input_blur_2_listener($event) {
            return ctx.onBlur($event);
          })("focus", function CapturumPasswordComponent_Template_cap_input_focus_2_listener($event) {
            return ctx.onFocus($event);
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, CapturumPasswordComponent_cap_validator_3_Template, 1, 2, "cap-validator", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("password-input", ctx.toggleMask);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.toggleMask);
          i0.ɵɵadvance();
          i0.ɵɵclassMap("cap-password " + ctx.styleClass);
          i0.ɵɵproperty("autocomplete", ctx.autocomplete)("type", ctx.isPasswordTypeText ? "text" : "password")("placeholder", ctx.placeholder)("readonly", ctx.readonly)("disabled", ctx.disabled)("styleClass", ctx.styleClass)("label", ctx.label);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.control);
        }
      },
      dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i1.CapturumValidatorComponent, i4.CapturumInputComponent],
      styles: ["[_ngcontent-%COMP%]:root{--cap-input-height: 30px;--cap-dynamic-filter-input-height: 30px;--cap-dynamic-filter-border-radius: 5px;--cap-dynamic-filter-border-width: 1px;--cap-dynamic-filters-hide-filters-on-mobile: false;--cap-dynamic-filters-filter-flex: 1;--cap-dynamic-filters-flex-wrap: wrap;--cap-dynamic-filter-width: 200px;--cap-dynamic-filter-label-font-size: 14px;--cap-dynamic-filter-label-font-weight: 600;--cap-dynamic-filter-label-font-family: \"Source Sans Pro\";--cap-dynamic-filter-label-font-color: #5f6062;--cap-dynamic-filter-placeholder-font-weight: 600;--cap-dynamic-filter-placeholder-font-color: #5f6062;--cap-dynamic-filter-icon-opacity: 1;--cap-dynamic-filter-icon-color: var(--cap-primary-color)}.password-input[_ngcontent-%COMP%]{position:relative}.password-input[_ngcontent-%COMP%]   .display-password-toggle[_ngcontent-%COMP%]{position:absolute;height:var(--cap-input-height);display:flex;align-items:center;justify-content:center;padding:0 10px;right:0;cursor:pointer;border-top-right-radius:3px;border-bottom-right-radius:3px;bottom:0}.password-input[_ngcontent-%COMP%]   .display-password-toggle[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-size:.875rem;font-weight:600;color:var(--primary-text)}[_nghost-%COMP%]     .validator{position:absolute}[_nghost-%COMP%]     .password-input .p-inputgroup input.p-inputtext{padding-right:40px;width:100%}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumPasswordComponent, [{
    type: Component,
    args: [{
      selector: 'cap-password',
      providers: [ValueAccessorBase.getProviderConfig(CapturumPasswordComponent)],
      template: "<div [class.password-input]=\"toggleMask\">\n  <div *ngIf=\"toggleMask\" class=\"display-password-toggle\" (click)=\"displayToggle()\">\n    <i [class]=\"isPasswordTypeText ? hideIcon || 'fal fa-eye-slash' : showIcon || 'fal fa-eye'\"></i>\n  </div>\n\n  <cap-input [autocomplete]=\"autocomplete\"\n             [class]=\"'cap-password ' + styleClass\"\n             [type]=\"isPasswordTypeText ? 'text' : 'password'\"\n             [placeholder]=\"placeholder\"\n             [readonly]=\"readonly\"\n             [disabled]=\"disabled\"\n             [styleClass]=\"styleClass\"\n             [label]=\"label\"\n             [(ngModel)]=\"value\"\n             (keyup)=\"onKeyUp.emit($event)\"\n             (input)=\"onInputEvent($event)\"\n             (blur)=\"onBlur($event)\"\n             (focus)=\"onFocus($event)\">\n  </cap-input>\n\n  <cap-validator *ngIf=\"control\" [control]=\"control\" [customName]=\"label\"></cap-validator>\n</div>\n",
      styles: [":root{--cap-input-height: 30px;--cap-dynamic-filter-input-height: 30px;--cap-dynamic-filter-border-radius: 5px;--cap-dynamic-filter-border-width: 1px;--cap-dynamic-filters-hide-filters-on-mobile: false;--cap-dynamic-filters-filter-flex: 1;--cap-dynamic-filters-flex-wrap: wrap;--cap-dynamic-filter-width: 200px;--cap-dynamic-filter-label-font-size: 14px;--cap-dynamic-filter-label-font-weight: 600;--cap-dynamic-filter-label-font-family: \"Source Sans Pro\";--cap-dynamic-filter-label-font-color: #5f6062;--cap-dynamic-filter-placeholder-font-weight: 600;--cap-dynamic-filter-placeholder-font-color: #5f6062;--cap-dynamic-filter-icon-opacity: 1;--cap-dynamic-filter-icon-color: var(--cap-primary-color)}.password-input{position:relative}.password-input .display-password-toggle{position:absolute;height:var(--cap-input-height);display:flex;align-items:center;justify-content:center;padding:0 10px;right:0;cursor:pointer;border-top-right-radius:3px;border-bottom-right-radius:3px;bottom:0}.password-input .display-password-toggle i{font-size:.875rem;font-weight:600;color:var(--primary-text)}:host ::ng-deep .validator{position:absolute}:host ::ng-deep .password-input .p-inputgroup input.p-inputtext{padding-right:40px;width:100%}\n"]
    }]
  }], () => [{
    type: i1.ValidatorService
  }, {
    type: i0.Injector
  }], {
    model: [{
      type: ViewChild,
      args: [NgModel, {
        static: true
      }]
    }],
    placeholder: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    toggleMask: [{
      type: Input
    }],
    hideIcon: [{
      type: Input
    }],
    showIcon: [{
      type: Input
    }],
    autoCloseTimeout: [{
      type: Input
    }],
    autocomplete: [{
      type: Input
    }],
    onInput: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    onKeyUp: [{
      type: Output
    }]
  });
})();
class CapturumPasswordModule {
  static {
    this.ɵfac = function CapturumPasswordModule_Factory(t) {
      return new (t || CapturumPasswordModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumPasswordModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, InputTextModule, KeyFilterModule, CapturumSharedModule, CapturumInputModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumPasswordModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumPasswordComponent],
      exports: [CapturumPasswordComponent],
      imports: [CommonModule, FormsModule, InputTextModule, KeyFilterModule, CapturumSharedModule, CapturumInputModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumPasswordComponent, CapturumPasswordModule };
