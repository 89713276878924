import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable()
export class PublicResponseFormService {
  private endpoint = 'public-response-form';
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  public getClientInfo(clientId: string, communicationId: string): Observable<any> {
    let params = new HttpParams();

    params = params.append('_meta[include]', 'leads.communication');

    return this.http.get(`${this.baseUrl}/${this.endpoint}/${clientId}/${communicationId}`, { params }).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  public sendResponseForm(clientId: string, communicationId: string, payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/${this.endpoint}/${clientId}/${communicationId}`, payload);
  }
}
