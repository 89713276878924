import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapItem } from '@capturum/ui/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormUtil } from '@shared/utils/form-util';
import { ToastService } from '@capturum/shared';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PublicResponseFormService } from '@modules/public-response-form/services/public-response-form.service';
import { Observable } from 'rxjs';
import { PublicRoutes } from '@core/enums/routes';
import { AddressCountry } from '@core/enums/address-country.enum';
import { Client } from '@modules/client/models/client.model';
import { Subject } from '@node_modules/rxjs';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-public-response-form',
  templateUrl: './public-response-form.component.html',
  styleUrls: ['./public-response-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CapturumDropdownModule,
    CapturumInputModule,
    CapturumTextareaModule,
    CapturumButtonModule,
    DecimalPipe,
  ],
})
export class PublicResponseFormComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public isPageLoaded = false;
  public validateUnitsInterested = true;
  public showEmailField = true;
  public showButton = true;
  public showPostField = true;
  public addressCountry = AddressCountry;
  public client: Client;
  public sendingTypes: MapItem[] = [
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Post',
      value: 'post',
    },
    {
      label: 'Post & E-mail',
      value: 'both',
    },
  ];

  public errMessage: any = {
    title: 'Mislukt',
    description: 'Niet alle velden zijn correct ingevuld.',
  };

  public methodNotChosen: any = {
    title: 'Mislukt',
    description: 'Ontvang per post of e-mail.',
  };

  private clientId: string;
  private communicationId: string;
  protected destroy$: Subject<boolean> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private responseFormService: PublicResponseFormService,
    private builder: UntypedFormBuilder,
    private toast: ToastService,
  ) {
    this.isPageLoaded = true;

    this.route?.params
      ?.pipe(
        tap(({ clientId, communicationId }) => {
          this.clientId = clientId;
          this.communicationId = communicationId;
        }),
        switchMap(() => {
          return responseFormService.getClientInfo(this.clientId, this.communicationId);
        }),
      )
      .subscribe(
        (client) => {
          this.client = client;

          let formValue = client;

          if (this.client.address) {
            formValue = { ...this.client.address, ...formValue };
          }

          if (this.client.company) {
            formValue.company_name = this.client.company.name;
          }

          this.form.patchValue(formValue);

          this.isPageLoaded = true;
        },
        (err) => {
          if (err && err.status === 500) {
            this.router.navigate([`/${PublicRoutes.responseForm}/${PublicRoutes.result}/${PublicRoutes.error}`]);
          } else {
            this.router.navigate([`/${PublicRoutes.responseForm}/${PublicRoutes.result}/${PublicRoutes.notFound}`]);
          }
          this.isPageLoaded = true;
        },
      );
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public updateCommunicationType($event: any): void {
    this.showEmailField = $event.value === 'email' || $event.value === 'both';
    this.showPostField = $event.value === 'post' || $event.value === 'both';
  }

  public submitResponseForm(): void {
    if (!this.form.value.sending_method) {
      this.toast.error(this.methodNotChosen.title, this.methodNotChosen.description);

      return;
    }

    if (!this.form.valid) {
      this.toast.error(this.errMessage.title, this.errMessage.description);
      FormUtil.markAsTouched(this.form);

      return;
    }

    this.saveForm(this.form.getRawValue()).subscribe(() => {
      this.router.navigate([`/${PublicRoutes.responseForm}/${PublicRoutes.result}/${PublicRoutes.success}`]);
    });
  }

  public enable(): void {
    this.showButton = false;
    this.form.enable();
  }

  private saveForm(payload: any): Observable<any> {
    return this.responseFormService.sendResponseForm(this.clientId, this.communicationId, payload);
  }

  private initForm(): void {
    this.form = this.builder.group({
      sending_method: ['both', Validators.required],
      email: [{ value: '', disabled: true }, Validators.required],
      street: [{ value: '', disabled: true }, Validators.required],
      housenumber: [{ value: '', disabled: true }, Validators.required],
      addition: [{ value: '', disabled: true }],
      zipcode: [{ value: '', disabled: true }, Validators.required],
      city: [{ value: '', disabled: true }, Validators.required],
      country: [{ value: '', disabled: true }, Validators.required],
      remarks: [null],
      company_name: [{ value: '', disabled: true }],
      units_interest: [null, Validators.required],
    });

    this.form
      .get('sending_method')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value === 'email') {
          this.form.get('email').setValidators([Validators.email, Validators.required]);
          this.form.get('street').clearValidators();
          this.form.get('housenumber').clearValidators();
          this.form.get('zipcode').clearValidators();
          this.form.get('city').clearValidators();
          this.form.get('country').clearValidators();
        } else if (value === 'post') {
          this.form.get('email').clearValidators();
          this.form.get('street').setValidators([Validators.required]);
          this.form.get('housenumber').setValidators([Validators.required]);
          this.form.get('zipcode').setValidators([Validators.required]);
          this.form.get('city').setValidators([Validators.required]);
          this.form.get('country').setValidators([Validators.required]);
        } else if (value === 'both') {
          this.form.get('email').setValidators([Validators.email, Validators.required]);
          this.form.get('street').setValidators([Validators.required]);
          this.form.get('housenumber').setValidators([Validators.required]);
          this.form.get('zipcode').setValidators([Validators.required]);
          this.form.get('city').setValidators([Validators.required]);
          this.form.get('country').setValidators([Validators.required]);
        }

        this.form.updateValueAndValidity({ emitEvent: false });
      });
  }
}
