<app-dashboard-header (onToggleSidebar)="toggleSidebar()"> </app-dashboard-header>

@if (navbarVisible) {
  <cap-horizontal-navbar [items]="navBarMenuItems"></cap-horizontal-navbar>
}

<app-dashboard-sidebar [sidebarVisible]="sidebarVisible" (onHide)="toggleSidebar(false)"> </app-dashboard-sidebar>

<div class="page-content px-2 px-xl-5 py-4">
  <router-outlet></router-outlet>
</div>
