import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@capturum/ui/api';
import { CapturumSharedModule } from '@capturum/ui/api';
import { ReactiveFormsModule } from '@angular/forms';
const _c0 = ["*"];
function FormFieldComponent_label_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
class FormFieldComponent {
  static {
    this.ɵfac = function FormFieldComponent_Factory(t) {
      return new (t || FormFieldComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FormFieldComponent,
      selectors: [["cap-form-field"]],
      inputs: {
        control: "control",
        label: "label"
      },
      ngContentSelectors: _c0,
      decls: 6,
      vars: 5,
      consts: [[1, "cap-form-field-label"], [4, "ngIf"], [1, "cap-form-field-validator"], [3, "control", "customName"]],
      template: function FormFieldComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div")(1, "div", 0);
          i0.ɵɵtemplate(2, FormFieldComponent_label_2_Template, 2, 1, "label", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(3);
          i0.ɵɵelementStart(4, "div", 2);
          i0.ɵɵelement(5, "cap-validator", 3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("error", (ctx.control == null ? null : ctx.control.errors) && (ctx.control == null ? null : ctx.control.dirty));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.label);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("control", ctx.control)("customName", ctx.label);
        }
      },
      dependencies: [i1.NgIf, i2.CapturumValidatorComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormFieldComponent, [{
    type: Component,
    args: [{
      selector: 'cap-form-field',
      template: "<div [class.error]=\"control?.errors && control?.dirty\">\n    <div class=\"cap-form-field-label\">\n        <label *ngIf=\"label\" >{{ label }}</label>\n    </div>\n\n    <ng-content></ng-content>\n\n    <div class=\"cap-form-field-validator\">\n        <cap-validator [control]=\"control\" [customName]=\"label\"></cap-validator>\n    </div>\n</div>\n"
    }]
  }], null, {
    control: [{
      type: Input
    }],
    label: [{
      type: Input
    }]
  });
})();
class CapturumFormFieldModule {
  static {
    this.ɵfac = function CapturumFormFieldModule_Factory(t) {
      return new (t || CapturumFormFieldModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumFormFieldModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule, CapturumSharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumFormFieldModule, [{
    type: NgModule,
    args: [{
      declarations: [FormFieldComponent],
      imports: [CommonModule, ReactiveFormsModule, CapturumSharedModule],
      exports: [FormFieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumFormFieldModule, FormFieldComponent };
