import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@node_modules/@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    if (!params.key.includes('reyersen_van_buuren.') && params.translateService) {
      return params.translateService.instant(`reyersen_van_buuren.${params.key}`, params.interpolateParams);
    }

    return `[[ ${params.key} ]]`;
  }
}
