import { TranslateLoader } from '@node_modules/@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslationIndexedDbModel } from '@capturum/complete';

export class TranslationLoader implements TranslateLoader {
  /**
   * Get the translations for the given language
   *
   * @param lang
   */
  public getTranslation(lang: string): Observable<any> {
    return new Observable((resolver) => {
      TranslationIndexedDbModel.query()
        .get(lang)
        .then(
          (result: any) => {
            resolver.next(result.value);
          },
          (err) => {
            resolver.next({});
          },
        )
        .catch(() => {
          resolver.next({});
        });
    });
  }
}
