import { Injectable } from '@angular/core';
import { AuthConfig, AuthService } from '@capturum/auth';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends AuthService {
  constructor(
    config: AuthConfig,
    http: HttpClient,
    private readonly router: Router,
  ) {
    super(config, http);
  }

  public handleSuccessfulLogin(response: any): void {
    if (response.method) {
      this.router.navigate(['/', 'auth', 'two-factor'], {
        queryParams: {
          method: response.method,
          userId: response.user_id,
        },
      });
    }
  }
}
