import { take, map } from 'rxjs/operators';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@capturum/auth';
import { AllRoutes } from '@core/enums/routes';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { ClientApiService } from '@modules/client/services/client-api.service';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { ClientSearchResultModel } from '@modules/client/models/client-search-result.model';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';

import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumSharedModule } from '@capturum/ui/api';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    RouterLink,
    CapturumSharedModule,
    FormsModule,
    ReactiveFormsModule,
    CapturumInputModule,
    CapturumButtonModule,
    MenuModule,
    TranslateModule,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onToggleSidebar: EventEmitter<void> = new EventEmitter<void>();

  public routes = AllRoutes;
  public profileMenuItems: MenuItem[];
  public settingPermissions: string[];
  public searchResultsVisible = false;
  public searchResults: ClientSearchResultModel[];
  public form: UntypedFormGroup;
  public searching: boolean;
  public noResults: boolean;
  public noResultsLabel: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private clientApi: ClientApiService,
    private fb: UntypedFormBuilder,
  ) {
    const currentUser = this.authService.getUser();

    if (currentUser) {
      this.translateService.setDefaultLang('nl');
      this.translateService.use(currentUser.locale?.code);
    }

    // Define all permissions that should be able to see the manage section
    this.settingPermissions = [
      'user.manage.tenant',
      'tenant.manage',
      'module.manage.tenant',
      'translation.manage.tenant',
      'role.manage.tenant',
    ];

    this.translateService
      .stream('header.logout.label')
      .pipe(take(2))
      .subscribe(() => {
        this.profileMenuItems = [
          {
            label: this.translateService.instant('header.profile.label'),
            icon: 'fas fa-user-circle',
            routerLink: ['/', this.routes.admin, this.routes.users, this.routes.profile],
          },
          {
            label: this.translateService.instant('header.logout.label'),
            icon: 'fas fa-sign-out-alt',
            command: () => {
              this.logout();
            },
          },
        ];
      });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public search(value: string): void {
    if (!value) {
      return;
    }

    this.searching = true;
    this.searchResults = null;
    this.noResults = false;

    this.clientApi
      .search(value.replace(' ', '_'))
      .pipe(
        map((response) => {
          return response.data;
        }),
      )
      .subscribe((result: ClientSearchResultModel[]) => {
        if (result && result.length > 0) {
          this.searchResults = this.highlightSearchTerm(result, value);
          this.noResults = false;
        } else {
          this.noResultsLabel = `${this.translateService.instant('search.no-results.label')} ${value}`;
          this.noResults = true;
          this.searching = false;
        }
      });
  }

  public showSearchResults(): void {
    this.searchResultsVisible = true;
  }

  public hideSearchResults(): void {
    this.searchResultsVisible = false;
    this.searchResults = null;
    this.form.get('search').patchValue(null);
  }

  public toggleSidebar(): void {
    this.onToggleSidebar.emit();
  }

  /**
   * Navigate to given url
   *
   * @param url
   */
  public navigate(url: string): void {
    this.router.navigate([url]);
  }

  /**
   * Logout and redirect to login screen
   */
  public logout(): void {
    this.authService.logout().subscribe((result: any) => {
      if (result) {
        // Navigate to admin part
        this.navigate('/auth/login');
      }
    });
  }

  public onSearch(): void {
    this.search(this.form.get('search').value);
  }

  /**
   * Replace occurence of searchterm with highlighted element in results
   *
   * @param results: ClientSearchResultModel[]
   * @param searchTerm: string
   *
   * @returns ClientSearchResultModel[]
   */
  private highlightSearchTerm(results: ClientSearchResultModel[], searchTerm: string): ClientSearchResultModel[] {
    const replaceStringWithHighlight = (value: string): string => {
      const regex = new RegExp(searchTerm, 'gi');

      return value.replace(regex, (match) => {
        return `<span class="search-result-highlight">${match}</span>`;
      });
    };

    for (const result of results) {
      result.full_name = replaceStringWithHighlight(result.full_name);

      if (result.found_in && result.found_in.length > 0) {
        for (let i = 0; i < result.found_in.length; i++) {
          result.found_in[i] = replaceStringWithHighlight(result.found_in[i]);
        }
      }
    }

    this.searching = false;

    return results;
  }

  private initForm(): void {
    this.form = this.fb.group({
      search: [null],
    });
  }
}
