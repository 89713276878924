import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { BasicLayoutComponent } from '@shared/layouts/basic-layout/basic-layout.component';
import { HeaderComponent } from '@shared/layouts/basic-layout/components/header/header.component';
import { SidebarComponent } from '@shared/layouts/basic-layout/components/sidebar/sidebar.component';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { AccordionModule } from 'primeng/accordion';
import { SplitButtonModule } from 'primeng/splitbutton';
import { HorizontalNavbarModule } from '@capturum/ui/horizontal-navbar';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MenuModule,
    DropdownModule,
    ToastModule,
    CapturumInputModule,
    CapturumDropdownModule,
    CapturumMultiSelectModule,
    CapturumTextareaModule,
    CapturumCheckboxModule,
    CapturumCalendarModule,
    CapturumInputSwitchModule,
    AccordionModule,
    SplitButtonModule,
    HorizontalNavbarModule,
    BasicLayoutComponent,
    HeaderComponent,
    SidebarComponent,
  ],
})
export class LayoutsModule {}
