import localeNL from '@angular/common/locales/nl';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';

import { environment } from '@environments/environment';

import { hmrBootstrap } from './hmr';
import { AppComponent } from './app/app.component';
import { TranslationLoader } from '@core/translate/translation-loader';
import { CustomMissingTranslationHandler } from '@core/translate/custom-missing-translation-handler';
import { CustomLoginService } from '@core/services/custom-login.service';
import { LoginModule } from '@capturum/login';
import { ApiModule } from '@capturum/api';
import { AuthenticationService } from '@core/services/authentication.service';
import { TwoFactorService } from '@core/services/two-factor.service';
import { AuthRoutes } from '@core/enums/routes';
import { AuthModule } from '@capturum/auth';
import { AppRoutingModule } from '@root/src/app/app-routing.module';
import { FormlyModule } from '@node_modules/@ngx-formly/core';
import { FormModule as CapturumFormlyModule, passwordValidator } from '@capturum/formly';
import { ToastModule } from 'primeng/toast';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LayoutsModule } from '@shared/layouts/layouts.module';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { createErrorHandler, init } from '@sentry/angular-ivy';
import { HttpMockRequestInterceptor } from '@core/interceptors/http-mock.interceptor';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DatePipe, registerLocaleData } from '@angular/common';
import { TranslateService, TranslateModule, MissingTranslationHandler, TranslateLoader } from '@ngx-translate/core';
import { CustomBaseDataResolver } from './app/core/resolvers/custom-base-data.resolver';
import { CompleteResolver, BaseDataResolver, CompleteModule } from '@capturum/complete';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localeNL);

if (environment.production) {
  enableProdMode();
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function onAuthError(): void {
  // Delete token
  localStorage.removeItem('token');

  // Redirect to login
  window.location.href = '/auth/login';
}

init({
    dsn: environment.sentryUrl,
    environment: environment.name,
    ignoreErrors: [
        'UnknownError ',
        'Handled unknown error',
        'NoSuchDatabaseError',
        'Non-Error exception captured',
        'NG0100: ExpressionChangedAfterItHasBeenCheckedError',
        'Failed to execute \'put\' on \'IDBObjectStore\'',
        // PDF viewer errors
        'startCleanup',
        'Cannot read properties of null (reading \'parentNode\')',
        'Transport destroyed',
    ],
});

const bootstrap = () => bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, LayoutsModule, ConfirmDialogModule, NgxPermissionsModule.forRoot(), ToastModule, CapturumFormlyModule, FormlyModule.forRoot(), AppRoutingModule, AuthModule.forRoot({
            baseUrl: environment.baseUrl,
            production: environment.production,
            authRejectionRoute: `${AuthRoutes.auth}/${AuthRoutes.login}`,
            unauthenticatedRejectionRoute: `${AuthRoutes.auth}/${AuthRoutes.login}`,
            twoFAService: TwoFactorService,
            authService: AuthenticationService,
        }), ApiModule.forRoot({
            baseUrl: environment.baseUrl,
            production: environment.production,
            onAuthError,
        }), LoginModule.forRoot({
            environment,
            productName: 'Vastgoedbeleggingen CRM',
            redirectAfterLogin: 'admin/clients',
            logoImageUrl: '/assets/images/layout/auth/auth-logo.svg',
            backgroundImageUrl: '/assets/images/layout/auth/auth-background.png',
            defaultLanguage: 'nl',
            passwordValidator: passwordValidator,
            loginService: CustomLoginService,
        }), CompleteModule.forRoot({
            indexedDbModels: [],
            databaseName: environment.databaseName,
            version: 1,
            environment: environment,
        }, NgxPermissionsService, NgxPermissionsModule), TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
            loader: {
                provide: TranslateLoader,
                useClass: TranslationLoader,
            },
        })),
        ConfirmationService,
        CompleteResolver,
        {
            provide: BaseDataResolver,
            useClass: CustomBaseDataResolver,
        },
        ConfirmationService,
        MessageService,
        TranslateService,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpMockRequestInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        provideAnimations(),
    ]
});

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap as any);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.error(err));
}
