<div class="public-response-form-result">
  <header class="header-picture"></header>

  <header class="row m-0 justify-content-center align-middle header-styling">
    <div class="header-container col-12 col-xl-6">
      <div class="container align-items-center"></div>

      @if (resultType === 'success') {
        <div class="header-info">Uw aanvraag is verzonden</div>
      }

      @if (resultMessage()?.isError) {
        <div class="header-info">Foutmelding</div>
      }
    </div>
  </header>

  <div class="row m-0 justify-content-center align-middle message">
    <div class="col-12 col-xl-6">
      @if (resultMessage()?.isError === false) {
        <div class="success-screen">
          <h5 class="text-left text-muted">{{ resultMessage().description }}</h5>
        </div>
      }

      @if (resultMessage()?.isError) {
        <div class="error-screen">
          <h5 class="text-left text-muted">{{ resultMessage().description }}</h5>
        </div>
      }
    </div>
  </div>

  @if (resultMessage()?.isError === false) {
    <div class="row m-0 justify-content-center align-middle">
      <div class="col-12 col-xl-6 m-0 p-0">
        <div class="company-info-section">
          <p class="blue-text">Heeft u vragen?</p>
          <p class="gray-text">Belt u ons gerust. Wij zijn u graag van dienst.</p>
          <button class="button-contact">
            <a href="tel:{{ clientInformation.phone }}" class="cap-form-field-label cursor-pointer">
              {{ clientInformation.phone }}
            </a>
          </button>
        </div>
      </div>
    </div>
  }

  @if (resultMessage()?.isError) {
    <div class="row m-0 justify-content-center align-middle">
      <div class="col-12 col-xl-6 m-0 p-0">
        <div class="company-info-section">
          <p class="blue-text">Gelieve contact met ons op te nemen</p>
          <button class="button-contact-blue">
            <a href="mailto:{{ clientInformation.email }}" target="_blank" class="text-primary fw-600 cursor-pointer">
              {{ clientInformation.email }}
            </a>
          </button>
          <button class="button-contact-white">
            <a href="tel:{{ clientInformation.phone }}" class="cap-form-field-label cursor-pointer">
              {{ clientInformation.phone }}
            </a>
          </button>
        </div>
      </div>
    </div>
  }
</div>
