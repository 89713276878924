import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

const urls = [];

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    for (const element of urls) {
      if (request.method === 'GET' && request.url.search(element.url) > -1) {
        return of(new HttpResponse({ status: 200, body: (element.json as any).default }));
      }
    }

    return next.handle(request);
  }
}
