import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicResponseFormComponent } from './pages/public-response-form/public-response-form.component';
import { PublicResponseFormRouterModule } from '@modules/public-response-form/public-response-form-router.module';
import { SharedModule } from '@shared/shared.module';
import { PublicResponseFormService } from '@modules/public-response-form/services/public-response-form.service';
import { PublicResponseFormResultComponent } from './pages/public-response-form-result/public-response-form-result.component';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PublicResponseFormRouterModule,
    ReactiveFormsModule,
    KeyFilterModule,
    CapturumInputSwitchModule,
    PublicResponseFormComponent,
    PublicResponseFormResultComponent,
  ],
  providers: [PublicResponseFormService],
})
export class PublicResponseFormModule {}
