import { LoginConfig, LoginService } from '@capturum/login';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastOptions } from '@node_modules/@capturum/ui/api/models/toast-options.model';

@Injectable({
  providedIn: 'root',
})
export class CustomLoginService extends LoginService {
  constructor(
    protected readonly config: LoginConfig,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly toastService: ToastService,
    protected readonly translateService: TranslateService,
    protected readonly formBuilder: UntypedFormBuilder,
    private readonly messageService: MessageService,
  ) {
    super(config, router, activatedRoute, toastService, translateService, formBuilder);
  }

  public onLoginSuccess(response: any): void {
    this.messageService.clear();

    if (!response?.method) {
      this.router.navigate([this.config.redirectAfterLogin]);
    }
  }

  public onTwoFactorLoginSuccess(response: any): void {
    if (response) {
      this.router.navigate([this.config.redirectAfterLogin]);
    }
  }

  public onResetSuccess(response: any): void {
    this.messageService.clear();

    super.onResetSuccess(response);
  }

  public onResetFail(response: any): void {
    const backendErrors = response.error?.errors;
    const errorTitle = 'Wachtwoord reset mislukt';
    const errorMessages = [];
    const toastConfig: Partial<ToastOptions> = {
      sticky: true,
    };

    if (backendErrors) {
      for (const property of Object.keys(backendErrors)) {
        errorMessages.push(backendErrors[property][0]);
      }
    } else {
      errorMessages.push('Er is iets misgegaan');
    }

    this.messageService.clear();
    this.toastService.error(errorTitle, errorMessages.join(' '), toastConfig);
  }
}
